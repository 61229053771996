export const apiUrls = {
  // SOV 
  contactUs: `contact_us/new/`,
  signIn: `identity/sign_in/`,
  signOut: `identity/sign_out/`,
  sovListLocation: 'billboardnew/locations/',
  sovFilterCity: 'billboardnew/locations_total_brand/',
  sovListCode: 'billboardnew/list_code/',
  sovFilter: 'billboardnew/location_filter/',
  sovYearFilter: 'billboardnew/year_filter/',
  sovListRoute: 'billboardnew/routes/',
  sovCreateBrands: 'billboardnew/brands/',
  sovContentHistory: args => `billboardnew/log_content/${args}/`,
  sovContentOccupancy: args => `billboardnew/log_content_occupancy/${args}/`,
  sovPlacement: args => `billboardnew/placements/${args}/`,
  updateSovPlacement: args => `billboardnew/edit_placements/${args}/`,
  sovEditLocation: args => `billboardnew/sov_edit/${args}/`,
  sovImageUpload: args => `billboardnew/${args}/image/update/`,
  downloadPlacement: `billboardnew/download/`,
  downloadPlacementWithProgress: `billboardnew/download_with_progress/`,
  deleteDownloadedPlacement: args => `billboardnew/delete_queue_file/${args}`,
  downloadPlacementQueue: `billboardnew/queue/`,
  downloadPlacementQueuFile: args => `billboardnew/queue/${args}/`,
  sovSearchImage: `billboardnew/image_search/`,
  deleteContent: args => `billboardnew/image/${args}/delete/`,
  addContent: args => `billboardnew/${args}/image/create/`,
  updateContent: args => `billboardnew/image/${args}/update/`,
  checkCoordinate: 'billboardnew/detect-near-billboard/',
  addPlacement: 'billboardnew/add-placement/',
  addSide: 'billboardnew/add-side/',
  checkSOVCode: 'billboardnew/check-sov-code/',
  switchIMSOV: 'billboardnew/switch-im-sov/',

  // billboard link aja
  billboardCampaign: `campaign/billboard/summary/`,
  billboardCampaignDetail: args => `campaign/billboard/${args}/`,
  createBillboardCampaign: `campaign/billboard/summary/`,
  deleteBillboardCampaign: args => `campaign/billboard/${args}/`,
  updateBillboardCampaign: args => `campaign/billboard/${args}/`,
  downloadBillboard: args => `billboardnew/download/${args}/`,
  listBillboard: `billboardnew/list/`,
  listBillboardType: `billboardnew/types/`,
  listBillboardCode: `billboardnew/codes/`,
  listPlacementCode: `billboard/placements/`, // not used anymore
  listStreet: `street/list/`,
  listCity: `billboardnew/cities/`,
  listMediaOwner: `billboardnew/media-owners/`,
  listSurrounding: `billboardnew/surrounding/`,
  downloadBillboardKML: `billboardnew/export_kml/`,
  uploadBillboardKML: `billboardnew/import_kml/`,
  listPlacement: 'billboardnew/list-placement/',
  placementDetail: args => `billboardnew/placement/${args}/`,
  updatePlacementDetail: args => `billboardnew/placement/${args}/update/`,
  deletePlacementDetail: args => `billboardnew/placement/${args}/delete/`,
  createPlacement: 'billboardnew/placement/create/',
  updatePhotos: 'billboardnew/read_drive/',
  bulkEditIM: 'billboardnew/bulk-update-placement/',

  //Measurement billboard campaign
  measurementBbCampaign: `campaign/billboard/`,
  measurementBbCampaignDetail: args =>`campaign/billboard/${args}/measurement_report/`,
  campaignPlacements: args => `campaign/${args}/placements/`,

  //Monitoring billboard campaign
  monitoringBbCampaign: `campaign/billboard/monitoring/`,
  monitoringBbCampaignDetail: args =>`campaign/billboard/${args}/monitoring_summary/`,
  monitoringBbCampaignReport: args =>`monitoring/campaign/${args}/report/`,
  monitoringBbCampaignReportDetail: (args, args2) =>`monitoring/campaign/${args}/report/${args2}/`,
  monitoringBbCampaignReportPhoto: args =>`monitoring/campaign/${args}/report_photo/`,
  monitoringBbCampaignStatus: args =>`monitoring/campaign/${args}/status_overview/`,

  //kml-converter
  csvToKml: `data/csv_to_kml/`,
  
  //device
  deviceList: 'jetson/device/list_devices',
  downloadDailyReport: 'jetson/download-reports',
  downloadDailyDeviceReport : args => `jetson/download-reports/daily/${args}`
};

export default {
  apiUrls
}
